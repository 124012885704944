import React, { useEffect } from "react";

const TextPage = ({ title, text }) => {
  useEffect(() => {
    const ScrollToTop = () => {
      window.scrollTo(0, 0);
    };
    ScrollToTop();
  }, [title]);
  
  return (
    <div>
      <div className="text-center bg-dark text-white py-5">
        <h1>{title}</h1>
      </div>

      <div className="canvas">
        <div className="mt-5">
        {text}
        </div>
      </div>
    </div>
  );
};

export default TextPage;
