import React from "react";
import { Link, useNavigate } from "react-router-dom";

const Card = (props) => {
  const { product } = props;
  const navigate = useNavigate();

  return (
    <div className="Card" onClick={() => navigate(`/product/${product._id}`)} type="button">
      <img
        src={`${process.env.REACT_APP_BASE_URL}/` + product.images[0]}
        alt="product-img"
        className="prod-img"
      />
      <div className="mt-2">
        <p className="category text-muted">{product.category}</p>
        <Link to={`/product/${product._id}`} className="title fs-5 fw-bold">
          {product.title}
        </Link>
        <p className="price fs-5 fw-bold">${product.price}</p>
      </div>
    </div>
  );
};

export default Card;
