import React from "react";
import Card from "../../components/Card";
import "./category.css";

const Category = () => {
  return (
    <div className="Category">
      <img
        src="https://d30fs77zq6vq2v.cloudfront.net/media/01022022/a1eA_Y3TFHZCP8TyZTxnaNMm-1392670483092.jpg"
        alt="img"
        width={"100%"}
        className="categoryImg"
      />
      <div className="canvas">
        <div className="grid mt-5">
          {/* <Card />
          <Card />
          <Card />
          <Card />
          <Card />
          <Card /> */}
        </div>
      </div>
    </div>
  );
};

export default Category;
