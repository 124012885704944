import React, { useEffect, useState } from "react";
import Card from "./Card";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import axios from "axios";

const NewArrival = () => {
  const [products, setProducts] = useState([]);

  const breakpoints = {
    300: {
      slidesPerView: 1,
    },
    350: {
      slidesPerView: 1.1,
    },
    400: {
      slidesPerView: 1.27,
    },
    550: {
      slidesPerView: 2,
    },
    770: {
      slidesPerView: 3,
    },
    1000: {
      slidesPerView: 4,
    },
    1400: {
      slidesPerView: 4.5,
    },
    1600: {
      slidesPerView: 5,
    },
  };

  useEffect(() => {
    const FetchProducts = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/products/getproducts`, 
          {withCredentials: true}
        );
        setProducts(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    FetchProducts();
  }, []);

  return (
    <div className="NewArrival mt-5">
      <div className="section-head w-75 mx-auto text-center">
        <h1 className="fw-bold">Últimos Modelos</h1>
        <p className="text-muted mt-2">
        Mira los últimos lanzamientos y las ofertas que tenemos para ti.
        </p>
      </div>
      <div className="d-flex justify-content-between canvas mt-4">
        <Swiper
          spaceBetween={10}
          breakpoints={breakpoints}
          navigation={true}
          modules={[Navigation]}
          className="w-100"
        >
          {products.map((elem) => {
            return (
              <SwiperSlide key={elem._id}>
                <Card product={elem}/>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};

export default NewArrival;
