// src/BrandSlider.js
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper';
import logo1 from "../images/640px-Apple-logo.png"
import logo2 from "../images/ulefone.png"
import logo3 from "../images/Samsung_Logo.svg.png"
import logo4 from "../images/Garmin_logo_2006.svg.png"
import logo5 from "../images/motorola-logo-268D98226D-seeklogo.com.png"
import logo6 from "../images/Nintendo_red_logo.svg.png"
import logo7 from "../images/Nokia_wordmark.svg.png"
import logo8 from "../images/OPPO_LOGO_2019.svg.png"
import logo9 from "../images/Panasonic_logo_(Blue).svg.png"
import logo10 from "../images/POCO-Emblem.png"
import logo11 from "../images/Realme-realme-_logo_box-RGB-01_with_out_back_ground.svg.png"
import logo12 from "../images/1280px-Dyson_logo.png"
import logo13 from "../images/smeg.png"
import logo14 from "../images/Sony_logo.svg.png"
import logo15 from "../images/ufesa-logo.png"
import logo16 from "../images/XiaomiLogoNew2.png"
import logo17 from "../images/ZTE-logo.svg.png"



// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// Install Swiper modules
SwiperCore.use([Autoplay, Pagination, Navigation]);

const brands = [
    { id: 1, logo: logo1 },
    { id: 2, logo: logo2 },
    { id: 3, logo: logo3 },
    { id: 4, logo: logo4 },
    { id: 5, logo: logo5 },
    { id: 6, logo: logo6 },
    { id: 7, logo: logo7 },
    { id: 8, logo: logo8 },
    { id: 9, logo: logo9 },
    { id: 10, logo: logo10 },
    { id: 11, logo: logo11 },
    { id: 12, logo: logo12 },
    { id: 13, logo: logo13 },
    { id: 14, logo: logo14 },
    { id: 15, logo: logo15 },
    { id: 16, logo: logo16 },
    { id: 17, logo: logo17 },
];

const BrandSlider = () => {
    return (
        <Swiper
            spaceBetween={30}
            slidesPerView={4}
            loop={true}
            autoplay={{ delay: 3000 }}
            pagination={false}
            navigation={false}
            breakpoints={{
                240: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                640: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                },
                768: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                },
                1024: {
                    slidesPerView: 5,
                    spaceBetween: 30,
                },
            }}
            className='BrandSlider canvas py-5'
        >
            {brands.map((brand) => (
                <SwiperSlide key={brand.id}>
                    <img src={brand.logo}  style={{ width: window.matchMedia("(max-width: 500px)").matches ?  "150px": '175px', height: 'auto' }} />
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

export default BrandSlider;
