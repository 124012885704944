import { Routes, Route, useLocation } from "react-router-dom";
import "./App.css";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import CartContextProvider from "./contexts/CartContext";
import Login from "./pages/Auth/Login";
import Register from "./pages/Auth/Register";
import Cart from "./pages/Cart/Cart";
import Order from "./pages/Order/Order";
import Category from "./pages/Category/Category";
import Home from "./pages/home/Home";
import Product from "./pages/Product/Product";
import TextPage from "./TextPage";

function App() {
  const location = useLocation();

  return (
    <CartContextProvider>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/men" element={<Category category={"men"} />} />
        <Route path="/women" element={<Category category={"women"} />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/orders" element={<Order />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/product/:id" element={<Product />} />

        <Route
          path="/privacy"
          element={
            <TextPage
              title={"Política de privacidad"}
              text={
                <>
                  <p>Política de privacidad</p>
                  <p><strong>Objeto</strong></p>
                  <p>La presente página Web ha sido diseñada para dar a conocer los servicios ofertados por la empresa EURO DIGITAL MULTISERVICES, S.L.U. con CIF B-13852330, con domicilio social en C/ Garcilaso De La Vega 21, Local 12, 38005 Santa Cruz de Tenerife, España.</p>

                  <p><strong>Propiedad intelectual e industrial</strong></p>
                  <p>Los derechos de propiedad intelectual de la página <a href="http://www.tutiendadelmovil.com">WWW.TUTIENDADELMOVIL.COM</a>, su código fuente, diseño, estructuras de navegación y los distintos elementos en ella contenidos son titularidad de EURO DIGITAL MULTISERVICES, S.L.U., a quien corresponde el ejercicio exclusivo de los derechos de explotación de los mismos en cualquier forma y, en especial, los derechos de reproducción, distribución, comunicación pública y transformación, de acuerdo con la legislación española y de la Unión Europea aplicable.</p>

                  <p><strong>Contenidos</strong></p>
                  <p>Se facilita a través de esta Web información acerca de servicios destinados a conocimiento público que en todo caso se sujetarán a los términos y condiciones expresamente detallados en cada momento y que son accesibles desde esta página Web, los cuales se sujetarán a las distintas disposiciones legales de aplicación.</p>

                  <p><strong>Acceso y uso</strong></p>
                  <p>Tanto el acceso a esta página Web, como el uso que pueda hacerse de la información y contenidos incluidos en la misma, será de la exclusiva responsabilidad de quien lo realice. Las condiciones de acceso a este Web estarán supeditadas a la legalidad vigente y los principios de la buena fe y uso lícito por parte del usuario de la misma, quedando prohibido con carácter general cualquier tipo de actuación en perjuicio de EURO DIGITAL MULTISERVICES, S.L.U. Se considerará terminantemente prohibido el uso de la presente página Web con fines ilegales o no autorizados.</p>

                  <p><strong>Responsabilidad</strong></p>
                  <p>EURO DIGITAL MULTISERVICES, S.L.U. no se hace responsable bajo ningún concepto por ningún tipo de daño que pudiesen ocasionar los Usuarios a la presente página Web, o a cualquier otra, por el uso ilegal o indebido de la misma, o de los contenidos e informaciones accesibles o facilitadas a través de ella.</p>

                  <p><strong>Servicio</strong></p>
                  <p>EURO DIGITAL MULTISERVICES, S.L.U. se reserva el derecho de suspender el acceso a su página Web, sin previo aviso, de forma discrecional y temporal, por razones técnicas o de cualquier otra índole, pudiendo asimismo modificar unilateralmente tanto las condiciones de acceso, como la totalidad o parte de los contenidos en ella incluidos.</p>

                  <p><strong>Generales</strong></p>
                  <p>Para toda cuestión litigiosa o que incumba a la Página Web de WWW.TUTIENDADELMOVIL.COM, será de aplicación la legislación española, siendo competentes para la resolución de todos los conflictos derivados o relacionados con el uso de esta página Web, los Juzgados y Tribunales del domicilio del usuario. El acceso a la página Web de WWW.TUTIENDADELMOVIL.COM implica la aceptación de todas las condiciones anteriormente expresadas.</p>

                  <p><strong>Hiperenlaces</strong></p>
                  <p>Los hiperenlaces contenidos en el sitio web de WWW.TUTIENDADELMOVIL.COM pueden dirigir a páginas web de terceros. WWW.TUTIENDADELMOVIL.COM no asume ninguna responsabilidad por el contenido, informaciones o servicios que pudieran aparecer en dichos sitios, que tendrán exclusivamente carácter informativo y que en ningún caso implican relación alguna entre WWW.TUTIENDADELMOVIL.COM y a las personas o entidades titulares de tales contenidos o titulares de los sitios donde se encuentren.</p>
                </>
              }
            />
          }
        />

        <Route
          path="/protection"
          element={
            <TextPage
              title={"Política de Protección de Datos"}
              text={
                <>
                  <p>Política de Protección de datos</p>
                  <p><strong>Finalidad del tratamiento</strong></p>
                  <p>En función del formulario que en cada caso complete el Usuario, los datos podrán ser tratados con las siguientes finalidades:</p>

                  <ul>
                    <li><strong>Usuarios registrados:</strong> La información que nos proporcione en el formulario de registro se utiliza para la gestión de Usuarios de la página web, con el fin de que pueda acceder a su perfil, consultar sus pedidos y realizar sus compras sin que tenga que introducir de nuevo sus datos identificativos (nombre, apellidos, email y teléfono) y domicilio de entrega.</li>
                    <li><strong>Newsletter:</strong> En los casos en los que nos otorgue su consentimiento según lo previsto en el artículo 21 de la Ley de Servicios de la Sociedad de la Información, utilizaremos sus datos identificativos y de contacto para enviarle información sobre novedades de la página web, noticias de interés, así como promociones y ofertas de nuestros productos y servicios.</li>
                    <li><strong>Contacto:</strong> Los datos personales que nos proporcione a través del formulario de contacto se tratarán únicamente para atender su consulta o petición.</li>
                    <li><strong>Formulario de reclamaciones:</strong> Los datos personales que nos proporcione a través del formulario se tratarán únicamente para atender su reclamación.</li>
                    <li><strong>Formulario de devoluciones:</strong> Los datos personales que nos proporcione a través del formulario se tratarán para la evaluación y gestión de la devolución o cambio solicitado y la recogida del producto si fuera necesario.</li>
                    <li><strong>Otras finalidades:</strong> La información facilitada se puede utilizar ocasionalmente para: Encuestas para mejorar nuestros servicios, gestionar las solicitudes de información, sugerencias y reclamaciones de clientes para su gestión y resolución.</li>
                  </ul>

                  <p><strong>Plazo de conservación</strong></p>
                  <p>Los datos personales proporcionados se conservarán durante el plazo correspondiente para cumplir con las obligaciones legales, mientras no se oponga al tratamiento o revoque el consentimiento.</p>

                  <p><strong>Legitimación</strong></p>
                  <p>EURO DIGITAL MULTISERVICES, S.L.U. está legitimado al tratamiento de datos personales, en base al consentimiento otorgado por el interesado mediante la firma o aceptación de los pertinentes formularios, para uno o varios fines específicos, tal y como recoge el artículo 6.1. a) del Reglamento General de Protección de datos personales.</p>

                  <p><strong>Exactitud de los datos</strong></p>
                  <p>Por otro lado, con el fin de que los datos obrantes en nuestros ficheros, informáticos y/o en papel, siempre correspondan a la realidad, se tratará de mantener actualizados.</p>

                  <p><strong>Destinatarios</strong></p>
                  <p>Los datos personales no serán cedidos o comunicados a terceros, salvo en los supuestos necesarios para el desarrollo, control y cumplimiento de la/s finalidad/es expresada/s, en los supuestos previstos según Ley.</p>

                  <p><strong>Derechos de los usuarios</strong></p>
                  <p>El interesado de los datos personales en todo caso podrá ejercitar los derechos que le asisten, de acuerdo con el Reglamento General de Protección de Datos.</p>

                  <p><strong>Medidas de seguridad</strong></p>
                  <p>Finalmente se informa que EURO DIGITAL MULTISERVICES, S.L.U, adoptará en su sistema de información las medidas técnicas y organizativas adecuadas, dando cumplimiento al principio de responsabilidad proactiva, a fin de garantizar la seguridad y confidencialidad de los datos almacenados.</p>
                </>
              }
            />
          }
        />

        <Route
          path="/aviso"
          element={
            <TextPage title={"Aviso Legal"} text={<>
              <p>Aviso legal</p>
              <p><strong>Ley Protección de Datos de Carácter Personal</strong></p>
              <p>De conformidad con lo establecido en Reglamento (UE) 2016/679 del Parlamento Europeo de Protección de Datos de Carácter Personal, y de la Ley Orgánica 3/2018, de 5 de diciembre (LOPDGDD), se informa al Cliente/Usuario de que los datos personales que nos facilita serán incluidos en un Registro de Actividades titularidad de EURO DIGITAL MULTISERVICES, S.L.U., con la finalidad de realizar una correcta gestión de la relación comercial, así como para informarle de novedades, productos y/o servicios tanto por medios escritos como electrónicos.</p>
              <p>Vd. podrá en cualquier momento revocar el consentimiento prestado para el envío de comunicaciones comerciales electrónicas remitiendo un correo a la dirección <a href="mailto:hola@tutiendadelmovil.com">hola@tutiendadelmovil.com</a></p>
              <p>Finalmente, le informamos de que puede ejercer sus derechos de acceso, rectificación, cancelación y/u oposición, limitación de tratamiento, y portabilidad, así como revocación del consentimiento prestado, dirigiéndose por escrito a EURO DIGITAL MULTISERVICES, S.L.U. sita en C/ Garcilaso De La Vega 21, Local 12, 38005 Santa Cruz de Tenerife, España.</p>

              <p><strong>Ley de Servicios de la Información y Comercio Electrónico</strong></p>
              <p>Según la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y Comercio Electrónico, se comunican los siguientes datos sobre el dominio de <a href="http://www.tutiendadelmovil.com">WWW.TUTIENDADELMOVIL.COM</a></p>
              <p><strong>Titular del Dominio</strong></p>
              <ul>
                <li>EURO DIGITAL MULTISERVICES, S.L.U.</li>
                <li>CIF B-13852330</li>
                <li>C/ Garcilaso De La Vega 21, Local 12, 38005 Santa Cruz de Tenerife, España.</li>
                <li>Correo electrónico: <a href="mailto:hola@tutiendadelmovil.com">hola@tutiendadelmovil.com</a></li>
              </ul>
              <p><strong>Nombre del dominio:</strong> WWW.TUTIENDADELMOVIL.COM</p>

            </>} />
          }
        />

        <Route
          path="/terms"
          element={
            <TextPage title={"Términos y Condiciones"} text={<>
              <p>1. IDENTIFICACIÓN</p>
              <p>Titular: EURO DIGITAL MULTISERVICES, S.L.U. (en adelante, "TU TIENDA DEL MÓVIL")<br />
                NIF: B-13852330<br />
                Domicilio: Calle Garcilaso de la Vega 21 Local 12, 38005 Santa Cruz de Tenerife. (España) <br />
                Página de contacto: 	tutiendadelmovil.com/contact<br />
                Correo electrónico: 	hola@tutiendadelmovil.com</p>

              <p>2. OBJETO</p>
              <p>Mediante el presente documento, se establecen los términos y condiciones (en adelante, las “Condiciones de Contratación” o “Condiciones”) que regulan la contratación de los productos ofrecidos por TU TIENDA DEL MÓVIL o usuarios en el sitio web https://www.tutiendadelmovil.com (en adelante, el “Sitio Web”), titularidad de TU TIENDA DEL MÓVIL. Se considerarán como Usuarios del Sitio Web a los consumidores y usuarios que accedan, naveguen y adquieran productos en el Sitio Web.<br />
                Las presentes Condiciones estarán vigentes durante toda la relación contractual entre el Usuario y TU TIENDA DEL MÓVIL que comenzará desde el momento de la aceptación de los mismos por parte del Usuario durante el proceso de compra de los productos (en adelante, los "Productos”) o durante el proceso de registro en el Sitio Web. <br />
                Cualquier cuestión que no hubiera sido expresamente prevista en las presentes Condiciones de Contratación se entenderá reservada a TU TIENDA DEL MÓVIL, sin perjuicio de la aplicación de lo dispuesto en la normativa vigente. Para cualquier tipo de compra de Productos el Usuario reconoce que ha leído y ha aceptado de manera expresa las presentes Condiciones.<br />
              </p>Ciertos Productos ofertados en el Sitio Web podrán ser objeto de unas condiciones específicas o particulares, las cuales serán aplicables y prevalecerán en caso de conflicto sobre las presentes Condiciones con respecto a la compra del Producto en cuestión. Las condiciones específicas o particulares de dichos Productos podrán sustituir, completar o, en su caso, modificar las presentes Condiciones de Contratación como corresponda.<br />
              Las presentes Condiciones, así como las condiciones específicas o particulares de los Productos que correspondan, se encuentran a disposición de todos los Usuarios a través la página https://www.tutiendadelmovil.com/terms de forma libre y gratuita. En caso de que el Usuario desee recibir una copia de una factura del pedido realizado o de las Condiciones de Contratación, específicas o particulares del producto en papel, puede contactar con TU TIENDA DEL MÓVIL a través del Formulario de Contacto.

              <p>3. ACCESO, REGISTRO Y BAJA</p>
              <p>3.1. Acceso y registro en el Sitio Web</p>

              <p>El registro del Usuario en el Sitio Web implicará que el Usuario pueda disfrutar de un área privada en el Sitio Web con prestaciones adicionales ofrecidas por TU TIENDA DEL MÓVIL. Se informa a los Usuarios que el registro no es obligatorio, pudiendo acceder y navegar por el Sitio Web sin la necesidad de estar registrado.
                El acceso, navegación y registro en el Sitio Web es gratuito. Para el acceso, navegación y registro en el Sitio Web es necesario ser mayor de edad. TU TIENDA DEL MÓVIL se reserva expresamente la facultad de requerir a los Usuarios la acreditación de esta edad y de otras informaciones tendentes a verificar la identidad del Usuario para garantizar el buen funcionamiento del Sitio Web. <br />
                Para formalizar el proceso de registro, el Usuario deberá proporcionar los datos necesarios solicitados por TU TIENDA DEL MÓVIL en el formulario de registro del Sitio Web. TU TIENDA DEL MÓVIL comunica al Usuario el carácter no obligatorio de la recogida de tales datos, salvo en los campos que se indique lo contrario. No obstante, la no cumplimentación o cumplimentación errónea o de forma imprecisa de dichos datos podrá impedir a TU TIENDA DEL MÓVIL prestar correctamente todos aquellos Servicios vinculados a tales datos, liberándole de toda responsabilidad por la no prestación, prestación defectuosa o prestación incompleta de estos Servicios.
                Bajo ningún concepto TU TIENDA DEL MÓVIL se responsabilizará de la veracidad de los datos de registro facilitados por los Usuarios, por lo que cada uno de ellos será responsable de las posibles consecuencias, errores y fallos que posteriormente puedan derivarse de la falta de calidad de los datos. TU TIENDA DEL MÓVIL se reserva el derecho de llevar a cabo cualesquiera comprobaciones, en el inicio y durante el tiempo en que la relación contractual subsista, sobre la veracidad de la información proporcionada en el proceso de alta por el Usuario, incluyendo datos personales.<br />
                Con la finalidad de completar el registro, en la pantalla inicial de registro, se ponen a disposición del Usuario la Política de Privacidad, Aviso Legal y las presentes Condiciones de Contratación, las cuáles deberá leer y aceptar para completar el proceso de registro. Tal y como se indica en las presentes Condiciones de Contratación, la cuenta de Usuario es personal e intransferible.<br />
                El Usuario determinará su Nombre, Apellido, correo electrónico y contraseña de acceso a la cuenta personal del Usuario para el Sitio Web en el momento de registro en la misma. Si el Usuario selecciona una contraseña que no cumple con los requisitos mínimos de seguridad será avisado, así como de las características que debe reunir dicha contraseña para una efectiva validez del alta del Usuario en el Sitio Web. <br />
                La contraseña creada tendrá una validez temporal ilimitada y el Usuario se compromete a hacer un uso diligente de la misma, manteniéndola en secreto y no transmitiéndola a ningún tercero. En consecuencia, el Usuario es el único responsable de la adecuada custodia y confidencialidad de cualesquiera identificadores y/o contraseñas que haya seleccionado y se compromete a no ceder su uso a terceros, ya sea temporal o permanente, ni permitir su acceso a personas ajenas. <br />
                Será responsabilidad del Usuario la utilización ilícita del Sitio Web por cualquier tercero ilegítimo, que emplee a tal efecto una contraseña a causa de una utilización no diligente o de la pérdida de la misma por el Usuario sin realizar la correspondiente comunicación a TU TIENDA DEL MÓVIL. TU TIENDA DEL MÓVIL pondrá todas las medidas que estén en su mano para evitar la suplantación de identidad y/o uso de perfiles de terceros. El Usuario podrá cambiar su contraseña cuando lo considere oportuno, a título enunciativo, pero no limitativo, cuando sospeche o constate que se haya producido la quiebra de la confidencialidad de la contraseña. <br />
                Una vez que el Usuario ha finalizado con éxito, por primera vez, el proceso de registro en el Sitio Web, TU TIENDA DEL MÓVIL pone a disposición del Usuario la posibilidad de comprar los Productos, sin necesidad de repetir el proceso de registro.
                El Usuario garantiza y declara que tiene la capacidad suficiente para aceptar y suscribir las presentes Condiciones de Contratación y cualquier otro término que pueda aplicarse en la compra de Productos.<br />
                El soporte electrónico en que consten las presentes Condiciones de Contratación celebradas por vía electrónica será admisible como prueba documental en caso de controversia entre las partes.<br />
              </p>

              <p>3.2. Verificación del perfil de Usuario</p>

              <p>Cuando el Usuario finalmente complete la inscripción en el Sitio Web, el Usuario recibirá la confirmación de la misma a través de un correo electrónico cuyo contenido determinará la confirmación de la suscripción del Usuario al Sitio Web por parte de TU TIENDA DEL MÓVIL. En el momento en el que el Usuario reciba la mencionada confirmación por correo electrónico y valide su suscripción, tendrá acceso a la compra de Productos ofrecidos en el Sitio Web.</p>

              <p>3.3. Procedimiento de baja del Usuario</p>
              <p>El Usuario puede desistir o solicitar su baja en el Sitio Web en cualquier momento, mediante comunicación escrita a hola@tutiendadelmovil.com, indicando su nombre de Usuario y aquella información adicional que TU TIENDA DEL MÓVIL pueda requerir para demostrar su vinculación al perfil requerido y evitar que terceros ajenos a los perfiles puedan solicitar la eliminación de las cuentas. <br />
                En cualquier caso, el Usuario podrá solicitar un nuevo registro, pudiendo TU TIENDA DEL MÓVIL no admitir dicho registro en los casos en los que el Usuario haya contravenido lo dispuesto en las presentes Condiciones de Contratación, Aviso Legal, en la Política de Privacidad o haya actuado de forma contraria a la ley, la moral o el orden público. Asimismo, TU TIENDA DEL MÓVIL podrá no admitir dicho registro en caso de conflicto o controversia suscitado entre las partes, que se encuentre por resolver o que haya finalizado con reconocimiento de dolo o culpa del Usuario y/o perjuicio a TU TIENDA DEL MÓVIL, a sus colaboradores, o a otros Usuarios.</p>



              <p>4. INFORMACIÓN SOBRE LOS PRODUCTOS</p>
              <p>4.1. Productos ofrecidos por TU TIENDA DEL MÓVIL</p>

              <p>Los Productos que TU TIENDA DEL MÓVIL ofrece a través del Sitio Web y que pueden ser objeto de compra por el Usuario son los siguientes: <br />
                Compra de Productos ofrecidos a través del Sitio Web: TU TIENDA DEL MÓVIL pone a disposición de sus Usuarios la posibilidad de compra de una variedad de dispositivos y terminales tecnológicos. Para conocer más sobre los productos ofrecidos por TU TIENDA DEL MÓVIL, puede acceder a la página principal del Sitio Web. <br />
                Tarifas para empresas y autónomos: TU TIENDA DEL MÓVIL pone a disposición de los Usuarios que sean autónomos y empresas tarifas especiales en la compra de terminales móviles. <br />
                No se admitirá la devolución de aquellos productos configurados a medida (personalizados), entendiendo por personalizados aquellos aparatos en los que te hayas registrado voluntariamente, activado las prestaciones de seguridad, geolocalización o patrones, pues la inicialización de estos dispositivos requiere el registro previo y voluntario de tus datos personales, los cuales quedan almacenados en la memoria del terminal. <br />
                En el caso de equipos Apple, específicamente, no se podrá tramitar la devolución de los dispositivos con la autenticación de doble factor activada. <br />
                Si no se hallase disponible el Producto contratado, el Usuario será informado mediante correo electrónico facilitado en el momento de su registro, pudiendo recuperar la cantidad abonada, procediendo TU TIENDA DEL MÓVIL a su devolución sin demora indebida. <br />
                Igualmente, TU TIENDA DEL MÓVIL podrá ofrecer al Usuario sin aumento de precio un producto de características similares que tenga la misma o superior calidad. En este caso, el Usuario podrá ejercer sus derechos de desistimiento y resolución en los mismos términos que si se tratara del bien inicialmente requerido. <br />
                Los datos incluidos en el Sitio Web en cada descripción de los Productos, en sus fotografías, representaciones gráficas o iconografías o vídeos, tienen una función meramente informativa. Por tanto, TU TIENDA DEL MÓVIL no se hace responsable de cualquier error que pueda surgir de dicha información, sin perjuicio de su compromiso en tomar todas las medidas necesarias a su alcance y en la mayor brevedad posible para la corrección de estos errores. <br />
                El funcionamiento del Sitio Web, así como el uso de aquellos Productos que sean contenidos digitales o servicios digitales, requiere acceso a Internet, siendo el Usuario responsable de todos los gastos asociados a dicha conexión, incluidos aquellos gastos derivados del tráfico de datos, independientemente del lugar en el que el Usuario haga uso del Sitio Web. La versión actual del Sitio Web está disponible a través de los siguientes navegadores, que serán compatibles y/o permitirán el uso de los Productos: <br />
                Firefox <br />
                Chrome <br />
                Explorer <br />
                Safari <br />
                Edge <br />
                Para experimentar una buena experiencia de usuario, es necesaria una velocidad de conexión suficiente. La compatibilidad e interoperabilidad relevante de los Productos con elementos digitales, el contenido o los servicios digitales se indicarán razonablemente en la descripción del Producto, según corresponda. <br />
              </p>

              <p>4.2. Reseñas </p>
              <p>Las reseñas y opiniones publicadas en el Sitio Web son efectuadas en su mayoría por Usuarios que han utilizado o adquirido el Producto. Las reseñas y opiniones son revisadas y aprobadas por un técnico de TU TIENDA DEL MÓVIL, solo excluyendo aquellas que puedan ser ofensivas o que no apliquen al producto aludido.</p>



              <p>PROCEDIMIENTO DE COMPRA, PAGO, ENTREGA DE PRODUCTOS Y EJECUCIÓN DE SERVICIOS</p>
              <p>5.1. Compra del Producto</p>
              <p>Para la compra de Productos a través del Sitio Web, el Usuario deberá facilitar una serie de datos con carácter previo a la compra, a través de un formulario facilitado al momento, necesario para poder gestionar la compra del Producto. El procedimiento de compra de los Productos comienza cuando el Usuario, una vez seleccionados los Productos que desea adquirir, realiza y recibe la confirmación del pedido.</p>

              <p>5.2. Condiciones económicas</p>
              <p>5.2.1. Precio</p>
              <p>Los precios están sujetos a modificaciones del operador y fabricantes y TU TIENDA DEL MÓVIL podrá realizar promociones exclusivas en el Sitio Web, reservándose el derecho de variar los precios y ofertas del catálogo de TU TIENDA DEL MÓVIL. El precio de los Productos comercializados en el Sitio Web se encuentra indicado junto a la ficha descriptiva de cada uno de ellos, siendo el precio de los mismos en todo caso mostrado en la moneda de curso oficial en Europa, el Euro (€). Todos los precios mostrados son precios finales, y salvo error tipográfico, incluyen expresamente el Impuesto sobre el Valor Añadido (IVA).<br />
                Las características y precios de los productos ofertados se actualizan constantemente. En caso de advertirse un error tipográfico en el contenido de la web, se procederá inmediatamente a su corrección. Si algún Usuario hubiera realizado la compra basada en un contenido erróneo, se le informará lo antes posible de la incidencia, teniendo el Usuario derecho a la rescisión del contrato de compra, sin coste alguno.<br />
                No obstante lo anterior, el precio final incluirá todos aquellos incrementos o descuentos que sean de aplicación, gastos repercutidos al Usuario y gastos adicionales por servicios accesorios, medios de pago, comisiones, etc.<br />
                Canon digital<br />
                Los precios de los Productos llevan incluido el Canon Digital. El Usuario abonará en el momento de la compra el Canon Digital correspondiente al producto adquirido más su IVA.<br />
                Memorias USB y otras tarjetas de memoria no integradas en otros dispositivos: 0,29 euros por unidad.<br />
                Discos integrados en un equipo, idóneos para la reproducción de videogramas, textos y fonogramas o de otros contenidos sonoros, visuales o audiovisuales: 6,60 euros por unidad.<br />
                Dispositivos portátiles reproductores de fonogramas, videogramas, textos o de otros contenidos sonoros, visuales o audiovisuales en formato comprimido, y dispositivos electrónicos portátiles con pantalla táctil: 3,81 euros por unidad.<br />
                Teléfonos móviles con funcionalidad de reproducción de fonogramas, videogramas y textos o de otros contenidos sonoros, visuales o audiovisuales: 1,33 euros por unidad.<br />
                Las anteriores cantidades llevan incluido el Impuesto sobre el Valor Añadido.<br />
                TAX FREE, aduanas y compras en el extranjero.<br />
                Los viajeros que puedan acogerse a la devolución del IVA (TAX FREE), contactar con TU TIENDA DEL MÓVIL para que se pueda emitir el formulario TAX FREE. Para ello, será necesario que el Usuario disponga de su DNI original y de la factura que acredite su compra en TU TIENDA DEL MÓVIL.</p>

              <p>5.2.2. Gastos de envío</p>
              <p>El coste del envío de móviles para los Usuarios es de cinco euros (5€) IVA incluido, excepto TVs y PCs donde el precio puede variar.<br />
                TU TIENDA DEL MÓVIL podrá cobrar los gastos de envío en situaciones de reexpedición de pedidos, por causas ajenas a TU TIENDA DEL MÓVIL y al servicio de mensajería en cuyo caso informará expresamente al Usuario.</p>

              <p>5.2.3. Métodos de pago</p>
              <p>Una vez completados los datos del formulario de compra, el Usuario deberá proceder al pago del precio del Producto. El Usuario tiene la obligación de pagar en el proceso de compra del Sitio Web mediante alguno de los siguientes métodos de pago:<br />
                Tarjeta de crédito (excepto American Express). Pago 100 % seguro a través de la pasarela de pagos de Stripe. (MasterCard Securecode y Verified By Visa).<br />
                Si el Usuario opta por el pago con tarjeta, el pago se cargará en el momento de la expedición del pedido, siempre y cuando haya obtenido la autorización previa de su banco o caja. Si el pago no ha sido autorizado, su pedido no podrá confirmarse. El pago en línea con tarjeta de crédito se realiza a través del sistema de seguridad "Secure Socket Layer", que codifica sus datos bancarios cuando son transmitidos por Internet.<br />
                Por razones de seguridad, TU TIENDA DEL MÓVIL puede verificar los pedidos pagados con tarjeta bancaria. En este caso, se pedirán o exigirán datos complementarios para poder aceptar la compra del Usuario. Por consiguiente, y para evitar retrasos con el envío, se ruega al Usuario que indique al hacer el pedido los siguientes datos:<br />
                Una dirección de correo electrónico privada o profesional<br />
                Un número de teléfono fijo o móvil (lugar de trabajo o domicilio) en el que podremos ponernos en contacto con usted en cualquier momento.<br />
                Esta modalidad de pago será obligatoria para los pedidos con envío Exprés.<br />
                Con PayPal (gastos adicionales del 3,50%)<br />
                Independientemente del medio de pago elegido por el Usuario, para cualquier pedido cuyo importe sea superior a un baremo fijado por TU TIENDA DEL MÓVIL, se le podrá solicitar un documento de identidad y un justificante de domicilio que deberá enviar a TU TIENDA DEL MÓVIL por correo electrónico. Estas medidas de seguridad constituyen una prueba fehaciente de la voluntad de TU TIENDA DEL MÓVIL para proteger a sus Usuarios contra eventuales fraudes bancarios.<br />
                TU TIENDA DEL MÓVIL utiliza métodos de pago ofrecidos por terceros que se rigen por sus propios términos y condiciones. TU TIENDA DEL MÓVIL no tiene acceso en ningún momento durante el proceso de registro y suscripción a ninguna información relativa al medio de pago que el Usuario haya elegido. Por lo tanto, no se hace responsable del uso fraudulento o indebido de estos datos de pago por parte de terceros. Además, en caso de que los métodos de pago no funcionen o no sean accesibles por razones fuera del control de TU TIENDA DEL MÓVIL, TU TIENDA DEL MÓVIL no será responsable de tales inconvenientes. Si esto le sucede, el Usuario podrá ponerse en contacto con TU TIENDA DEL MÓVIL a través de los canales dispuestos para ello en el Sitio Web para que pueda, en su caso, ofrecerle una solución.<br />
                Si en el momento de efectuarse el pago se produjese una incidencia que impida realizar el pago, el Usuario recibirá un mensaje automático de error de pago y su pedido, reserva o suscripción no será tramitada hasta no completarse con éxito el proceso de pago.</p>

              <p>5.3. Confirmación de la adquisición del Producto</p>
              <p>Cuando el Usuario confirme el pedido, recibirá la confirmación del mismo a través el correo electrónico facilitado a TU TIENDA DEL MÓVIL, cuyo contenido determinará la recepción del pedido por parte de TU TIENDA DEL MÓVIL y, en su caso, los plazos y la fecha estimada de entrega, así como una copia de las presentes Condiciones de Compra.<br />
                En el caso de que el Usuario quiera modificar el pedido, una vez realizado el mismo, se pone a disposición del Usuario el servicio telefónico de atención al cliente de TU TIENDA DEL MÓVIL.</p>

              <p>5.4. Envío del Producto</p>
              <p>Los productos comprados en TU TIENDA DEL MÓVIL serán enviados a España Peninsular e Islas Baleares, excluyendo la Comunidad de Canarias, Ceuta y Melilla. TU TIENDA DEL MÓVIL no suministra productos a terceros países.</p>

              <p>5.4.1. Modalidades de envío</p>
              <p>TU TIENDA DEL MÓVIL enviará el pedido a la dirección facilitada por el Usuario, una vez transcurridas, aproximadamente, setenta y dos (72) horas hábiles desde la confirmación del pedido.<br />
                Por su seguridad, el mensajero o el punto autorizado únicamente entregarán el pedido en mano, al titular del mismo y en la dirección de entrega o punto autorizado acordado. Para ello, se pedirá identificación, debiendo mostrar el NIF/NIE. Se recomienda a los Usuarios indicar como dirección de entrega, una dirección en la que se encuentre habitualmente, en caso de que ausencias prolongadas del domicilio.<br />
                TU TIENDA DEL MÓVIL hace todo lo posible para respetar los plazos de entrega mencionados en el Sitio Web. Sin embargo, TU TIENDA DEL MÓVIL no se hace responsable de las consecuencias debidas a un retraso de envío o a una pérdida del paquete por parte de usted mismo o por razones o causas imprevisibles o de fuerza mayor.<br />
                En caso de que no recibiera un paquete en el plazo indicado, se llevará a cabo una investigación junto con el transportista que podría durar varios días. Durante este período, no se podrá efectuar ningún reenvío ni reembolso del pedido por parte de TU TIENDA DEL MÓVIL.<br />
                En caso de que hubiera disconformidad sobre el Producto entregado (deterioro, falta de conformidad, cantidad equivocada, etc.) el Usuario deberá firmar e indicarlo a mano en el albarán. No obstante, dispone de un plazo de cuarenta y ocho horas (48h) para indicarnos la incidencia que ocurrida a través del formulario de contacto.<br />
                En dicha comunicación el Usuario deberá indicar mediante fotografías u otras pruebas, los defectos o causas de la falta de conformidad de los Productos, así como cualquier dato que permita la identificación del Producto.<br />
                Tras recibir dicha comunicación, se analizará la incidencia por parte de TU TIENDA DEL MÓVIL. En la mayor brevedad posible se comunicará al Usuario una respuesta sobre la reclamación. Dicha respuesta estará totalmente justificada de manera objetiva atendiendo a las características del Producto en cuestión.</p>

              <p>5.4.2. Entrega del producto</p>
              <p>Una vez entregado el Producto, el Usuario deberá custodiar el mismo con la debida diligencia y, en su caso, en estricto cumplimiento de las condiciones particulares aplicables al Producto. Cualesquiera daños y/o deterioros producidos en el Producto como consecuencia de la falta de diligencia por parte del Usuario no podrán considerarse como defectos de falta de conformidad y, por tanto, no estarán cubiertos por la garantía referida en estas Condiciones.<br />
                TU TIENDA DEL MÓVIL únicamente será responsable de los daños producidos en el Producto adquirido y efectivamente abonado por el Usuario cuando tales daños sean directamente imputables a TU TIENDA DEL MÓVIL. En cualquier caso, el Usuario podrá comunicar cualquier falta de conformidad del Producto de acuerdo con lo establecido en el presente documento y la legislación aplicable.<br />
                TU TIENDA DEL MÓVIL no será responsable de los daños o desperfectos en el Producto adquirido, abonado y entregado cuando dichos daños se hayan producido como consecuencia de acciones u omisiones del Usuario, fuerza mayor o caso fortuito, o se deban a la falta de diligencia debida del Usuario en la custodia del Producto.<br />
                TU TIENDA DEL MÓVIL no se hace responsable de mantener un nivel mínimo de stock o de garantizar el reabastecimiento o reposición del Producto adquirido por el Usuario.</p>


              <p>DERECHO DE DESISTIMIENTO</p>

              <p>6.1. Derecho de desistimiento</p>
              <p>Salvo que otras condiciones más favorables para el Usuario se determinen en las correspondientes condiciones específicas o particulares, el Usuario podrá ejercer su derecho de desistimiento y, sin indicar el motivo, podrá devolver los Productos que haya adquirido a través del Sitio Web en un plazo máximo de catorce (14) días naturales desde (i) el día de la celebración del acuerdo de los contratos de compra de productos; o (ii) el día que el Usuario o un tercero por él indicado, adquiera la posesión del Producto. En el caso de la entrega de Producto, el día en el que se adquiera la posesión del Producto, podrá determinarse también de la siguiente manera:
                <ul>
                  <li>En caso de entrega de múltiples Productos encargados por el Usuario en el mismo pedido y entregados por separado, el día que el Usuario o un tercero por él indicado, adquiera la posesión material del último de los Productos;</li>
                  <li>En caso de entrega de un Producto compuesto por múltiples componentes o piezas, el día que el Usuario o un tercero por él indicado, adquiera la posesión del último componente o pieza; o</li>
                  <li>En caso de contratos para la entrega periódica de Producto durante un plazo determinado, el día que el Usuario o un tercero por él indicado, adquiera la posesión material del primero de esos Productos.</li>
                </ul>
              </p>

              <p>6.2. Excepciones al derecho de desistimiento</p>
              <p>El Usuario acepta y reconoce que, por su naturaleza, no todos los Productos ofertados mediante el Sitio Web se encuentran amparados por el derecho de desistimiento y, en concreto, reconoce que queda excluido del derecho de desistimiento la compra de los siguientes Productos:</p>
              <ul>
                <li>Productos hechos a medida o conforme a las especificaciones del Usuario.</li>
                <li>En el caso de equipos Apple, específicamente, los dispositivos con la autenticación de doble factor activada.</li>
              </ul>

              <p>6.3. Procedimiento de desistimiento</p>
              <p>La comunicación de la voluntad de desistir del contrato por parte del Usuario se tendrá que formalizar rellenando el formulario de desistimiento. A tal efecto, el Usuario podrá utilizar el modelo de formulario de desistimiento que podrá descargar aquí. Una vez completada toda la información, deberá hacer llegar la solicitud a TU TIENDA DEL MÓVIL a través del correo electrónico hola@tutiendadelmovil.com.</p>
              <p>La devolución de las cantidades debidas al Usuario se hará efectiva en el plazo de catorce (14) días siguientes a la fecha en que el Usuario hubiese informado de su decisión de desistimiento, en la misma forma en la que se efectuó el pago. En cualquier caso, TU TIENDA DEL MÓVIL puede retener el reembolso hasta la efectiva recepción del Producto o de la prueba de devolución de la misma.</p>
              <p>En el caso de los Productos que como consecuencia de la procedencia de este derecho de desistimiento haya que devolver, el Usuario deberá hacerse cargo del coste directo de la devolución y deberá devolver el producto en un período máximo de catorce (14) días naturales a contar desde la fecha en que se comunique el derecho de desistimiento a TU TIENDA DEL MÓVIL.</p>
              <p>Cada Producto deberá devolverse en la misma forma que se entregó, sin usar o con las características de su uso normal y cotidiano, incluyendo los accesorios inherentes al mismo, y con todas las etiquetas y documentación accesoria original.</p>
              <p>Los gastos de devolución del pedido correrán a cargo del Usuario.</p>

              <p>7. GARANTÍA Y DEVOLUCIÓN DEL PRODUCTO</p>

              <p>7.1. Garantía del producto</p>
              <p>TU TIENDA DEL MÓVIL responderá ante los Usuarios de cualquier falta de conformidad que se manifieste en los Productos en los siguientes plazos:</p>
              <ul>
                <li>Garantía general de Productos: Un (1) año desde la entrega del Producto.</li>
              </ul>
              <p>No están cubiertas por la garantía las averías producidas como consecuencia de un uso inadecuado, causa de fuerza mayor, instalación, manipulación o reparación por personal ajeno o no autorizado.</p>

              <p>7.2. Devolución del Producto</p>
              <p>Para realizar una devolución con motivo de una falta de conformidad del Producto, el Usuario deberá hacer llegar la solicitud a TU TIENDA DEL MÓVIL a través del formulario de contacto. Una vez recibida la solicitud, TU TIENDA DEL MÓVIL informará al Usuario del proceso a seguir para la devolución del Producto.</p>
              <p>Cada Producto a devolver deberá haber sido objeto de un uso correcto y conforme con las instrucciones de uso proporcionadas por TU TIENDA DEL MÓVIL, y el Usuario deberá conservar todas las etiquetas, embalajes y documentación original del Producto.</p>
              <p>Una vez recibido el Producto y constatado el estado del mismo o los defectos alegados por el Usuario, TU TIENDA DEL MÓVIL informará al Usuario del régimen de la puesta en conformidad, pudiendo optar el Usuario por la reparación, sustitución, reducción del precio o resolución del contrato.</p>


              <p>8. Atención al Cliente</p>
              <p>TU TIENDA DEL MÓVIL como responsable del Sitio Web y en la medida en que se comporte como encargado de la comercialización de los Productos ofrecidos a través del Sitio Web, pone a disposición del Usuario un servicio de Atención al Cliente, disponible de 10h a 18h de Lunes a Viernes, en el que serán atendidas todas las consultas, quejas y sugerencias plantadas en relación con la compra de y/o suscripción a Productos a través del Sitio Web.</p>
              <p>Concretamente, las vías de contacto puestas a disposición son las siguientes:</p>
              <ul>
                <li>Correo electrónico: hola@tutiendadelmovil.com</li>
                <li>Atención telefónica: +34 XXXXXXXXXXX</li>
              </ul>
              <p>Asimismo, el Usuario también puede ponerse en contacto con TU TIENDA DEL MÓVIL a través de los formularios dispuestos a tal fin en el Sitio Web. TU TIENDA DEL MÓVIL se compromete a responder a las solicitudes en el plazo más breve posible y, en todo caso, dentro de los plazos legalmente establecidos.</p>

              <p>9. Nulidad Parcial</p>
              <p>Todas las cláusulas o extremos de las presentes Condiciones de Contratación deben ser interpretadas de forma independiente y autónoma, no viéndose afectadas el resto de estipulaciones en caso de que una de ellas haya sido declarada nula por sentencia judicial o resolución firme.</p>
              <p>En el caso de cualquier disposición de las Condiciones fuese declarada nula o inaplicable, en su totalidad o en parte, por cualquier juzgado, tribunal u órgano administrativo competente, dicha nulidad o inaplicación no afectará a las restantes disposiciones de las Condiciones. En este sentido, TU TIENDA DEL MÓVIL sustituirá la cláusula o cláusulas afectadas por otra u otras que preserven los efectos perseguidos por las presentes Condiciones de Contratación.</p>

              <p>10. Idioma</p>
              <p>Todo el proceso de contratación será realizado en castellano. No obstante, TU TIENDA DEL MÓVIL puede traducir las presentes Condiciones, las condiciones específicas o particulares, la Política de Privacidad, el Aviso Legal y, en definitiva, cualquier política o procedimiento que pueda ser publicado en el Sitio Web en el caso de que el Usuario consumidor lo solicite. La versión española será la que prevalezca en caso de conflicto.</p>

              <p>11. Notificaciones</p>
              <p>Todas las comunicaciones entre TU TIENDA DEL MÓVIL y el Usuario relativas a la compra de los Productos a través del Sitio Web se realizarán por escrito y de conformidad con los procedimientos de comunicación establecidos en este documento. En el caso de que no se indique ninguna dirección específica, las comunicaciones a TU TIENDA DEL MÓVIL se realizarán a la dirección de correo electrónico de la primera cláusula.</p>

              <p>12. Responsabilidades</p>
              <p>12.1. Responsabilidad de TU TIENDA DEL MÓVIL y del Usuario</p>
              <p>Tanto el Usuario como TU TIENDA DEL MÓVIL se comprometen a cumplir con sus obligaciones legales y contractuales generadas en virtud del presente documento y responderán de las infracciones en que hubiesen incurrido personalmente, quedando indemne la parte contraria frente a cualquier error, culpa o negligencia no imputable a ella, y a todo perjuicio que se derivase de dichas infracciones o errores imputables a la otra parte contratante.</p>
              <p>Además de lo contenido en el Aviso Legal y en otros apartados de las presentes Condiciones de Compra, TU TIENDA DEL MÓVIL no se hace responsable de ningún retraso o incumplimiento de sus obligaciones en virtud de estas Condiciones de Compra si el retraso o incumplimiento se debe a:</p>
              <ul>
                <li>el incumplimiento de un tercero (incluyendo, pero no limitándose, a los proveedores de servicios de pasarelas de pago); o</li>
                <li>cualquier causa que esté fuera de su control razonable, tales como: fuerza mayor, problemas al acceder a Internet, problemas tecnológicos más allá de una gestión diligente y razonable, acciones u omisiones de terceros, entre otros.</li>
              </ul>
              <p>En todos los casos mencionados en los puntos anteriores, no habrá compensación para el Usuario por daños o pérdidas, en la medida en que lo permita la legislación vigente.</p>
              <p>TU TIENDA DEL MÓVIL no será, en ningún caso, responsable, por los daños y perjuicios de cualquier tipo derivados, directa o indirectamente, de la falta de lectura de estas Condiciones de Contratación. El Usuario es el único responsable de las infracciones en las que pueda incurrir o de los perjuicios que pueda causar por la utilización incorrecta del Sitio Web, quedando TU TIENDA DEL MÓVIL exonerado de cualquier clase de responsabilidad que se pudiera derivar por las acciones del Usuario que contravenga lo establecido en las presentes Condiciones de Contratación.</p>
              <p>TU TIENDA DEL MÓVIL está facultado para suspender, sin penalización o derecho de compensación ante el Usuario, temporalmente, y sin previo aviso, la accesibilidad al Sitio Web por cualquier motivo, incluyendo pero no limitándose a operaciones de mantenimiento, reparación, actualización o mejora, así como por motivos de seguridad.</p>

              <p>13. Resolución</p>
              <p>Además de por las causas legalmente previstas y del derecho de desistimiento del Usuario, será causa de resolución anticipada el incumplimiento por parte del Usuario de las obligaciones asumidas en virtud de estas Condiciones de Contratación. TU TIENDA DEL MÓVIL se reserva el derecho a resolver la relación contractual con efectos inmediatos, en el caso de que el Usuario utilice el Sitio Web en un modo no adecuado a su finalidad o cause daños a los mismos de forma dolosa o gravemente negligente o por incumplimiento de cualquiera de los términos recogidos en las presentes Condiciones de Compra.</p>
              <p>En caso de no estar conforme con dicha decisión, el Usuario podrá dirigir cualquier reclamación rellenando el formulario del portal de contacto de TU TIENDA DEL MÓVIL y TU TIENDA DEL MÓVIL podrá decidir, de forma justificada y razonable, si readmitir al Usuario y permitir su uso del Sitio Web.</p>
              <p>TU TIENDA DEL MÓVIL podrá impedir al Usuario cualquier uso posterior a la fecha de terminación de estas Condiciones de Contratación de aquellos Productos que sean contenidos digitales y/o servicios digitales, en particular haciendo que estos no sean accesibles para el Usuario o inhabilitándole la cuenta de usuario, en su caso.</p>

              <p>14. Protección de Datos</p>
              <p>De conformidad con lo dispuesto en el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo de 27 de abril de 2016 relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos y por el que se deroga la Directiva 95/46/CE (en adelante, “RGPD”) y la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales (en adelante, “LOPDGDD”) todos los datos de carácter personal facilitados durante la utilización del Sitio Web serán tratados de conformidad con los dispuesto en la Política de Privacidad, que el Usuario debe leer y, en su caso, aceptar.</p>

              <p>15. MISCELÁNEA</p>
              <p>15.1. Modificación de las Condiciones</p>
              <p>TU TIENDA DEL MÓVIL puede modificar los términos y condiciones aquí establecidos, en todo o en parte. Los cambios se publicarán en el Sitio Web de forma similar a como están disponibles estas Condiciones de Contratación, informándose de forma previa al Usuario en el caso de resultar sustanciales para su aceptación o rechazo en un plazo razonable.</p>
              <p>En cualquier caso, se considerará que el Usuario acepta expresamente dichas modificaciones o actualizaciones si realiza una nueva contratación de Producto a través del Sitio Web o, en su caso, si continúa usando o disfrutando del Producto o los servicios de TU TIENDA DEL MÓVIL sin manifestar de forma expresa su rechazo en el plazo concedido.</p>
              <p>En caso de ser aceptadas por el Usuario, las nuevas Condiciones sustituirán plenamente a las Condiciones vigentes hasta ese momento, comenzando a surtir efecto en las nuevas contrataciones de Producto que el Usuario solicite a partir de la fecha de aceptación de las nuevas Condiciones o, en el caso de Productos de tracto sucesivo, desde el momento en que se produzca su aceptación.</p>

              <p>15.2. Incumplimiento de las Condiciones</p>
              <p>Si el Usuario incumple estas Condiciones de Contratación, TU TIENDA DEL MÓVIL se reserva su derecho de suspender o restringir el uso del Sitio Web e incluso de cancelar su cuenta de usuario sin previo aviso cuando existan causas justificadas y razonables para ello y, en su caso, reclamar al Usuario por los daños y perjuicios ocasionados a TU TIENDA DEL MÓVIL. Dichas acciones no darán derecho al Usuario a ningún tipo de compensación frente a TU TIENDA DEL MÓVIL. TU TIENDA DEL MÓVIL tiene la obligación informar y colaborar con las autoridades competentes en el caso de detectar alguna infracción de la ley aplicable o sospecha de que un Usuario está cometiendo algún delito.</p>

              <p>16. LEGISLACIÓN APLICABLE Y JURISDICCIÓN</p>
              <p>Para todas las controversias, acciones o reclamaciones que puedan surgir en relación con la interpretación y aplicación de las presentes Condiciones de Contratación y siempre que la legislación así lo permita, el Usuario y TU TIENDA DEL MÓVIL podrán someter cualquier conflicto a la jurisdicción de los Juzgados y Tribunales de la ciudad de Madrid (España) o, en caso de ser imperativo por la normativa sobre consumidores, a la jurisdicción de residencia del Usuario.</p>
              <p>En el caso de que el Usuario sea considerado como consumidor y tenga su residencia habitual en la Unión Europea, también contará con la protección que le ofrezca cualquier disposición imperativa de la legislación aplicable a su país de residencia.</p>
              <p>Asimismo, según lo establecido en el Reglamento (UE) nº 524/2013 del Parlamento Europeo y del Consejo, de 21 de mayo de 2013, relativo a la resolución de litigios en línea en materia de consumo, en caso de controversia, si así lo desea, el Usuario podrá acudir a la “Plataforma de Resolución de Controversias de la Comisión” para intentar resolver extrajudicialmente cualquier controversia que se derive del uso del Sitio Web por parte del Usuario. A través del siguiente enlace, el Usuario podrá enviar su reclamación a un organismo de litigios autorizado por la Comisión: http://ec.europa.eu/consumers/odr/</p>
              <p>En todo caso, para cualquier reclamación previa a la judicial en el uso del sitio web, se podrá dirigir al Servicio de Atención al cliente a través del Portal de Contacto del Sitio Web, https://tutiendadelmovil.com/contact, dentro del plazo de un (1) mes desde que se tiene conocimiento por parte del Cliente del hecho que la motiva, pudiendo solicitar documento acreditativo de la presentación y contenido de la queja o reclamación.</p>
              <br />
              <p>Última actualización: Mayo 2024</p>
              <p>Copyright © EURO DIGITAL MULTISERVICES, S.L.U. Todos los derechos reservados.</p>
            </>} />
          }
        />

        <Route
          path="/cookies"
          element={
            <TextPage title={"Política de Cookies"} text={<>

              <p>Cláusula Cookies</p>
              <p>En EURO DIGITAL MULTISERVICES, S.L.U. utilizamos cookies con el objetivo de prestar un mejor servicio y proporcionarte una mejor experiencia en tu navegación. Queremos informarte de manera clara y precisa sobre las cookies que utilizamos, detallando a continuación, que es una cookie, para qué sirve, qué tipos de cookies utilizamos, cuáles son su finalidad y cómo puedes configurarlas o deshabilitarlas si así lo deseas.</p>

              <p><strong>¿Qué es una cookie y para qué sirve?</strong></p>
              <p>Una Cookie es un pequeño archivo que se almacena en el ordenador del usuario, tablet, smartphone o cualquier otro dispositivo con información sobre la navegación. Las cookies son esenciales para el funcionamiento de internet, aportando innumerables ventajas en la prestación de servicios interactivos y facilitando la navegación y usabilidad de nuestra web. En ningún caso las cookies podrían dañar tu equipo. Por contra, el que estén activas nos ayuda a identificar y resolver los errores.</p>

              <p><strong>¿Qué tipos de cookies utilizamos?</strong></p>
              <ul>
                <li><strong>Según la entidad que la gestiona:</strong>
                  <ul>
                    <li><strong>Cookies propias:</strong> Son aquellas que se envían a tu equipo desde nuestros propios equipos o dominios y desde el que prestamos el servicio que nos solicitas.</li>
                    <li><strong>Cookies de terceros:</strong> Son aquellas que se envían a tu equipo desde un equipo o dominio que no es gestionado por nosotros, sino por otra entidad colaboradora.</li>
                  </ul>
                </li>
                <li><strong>Según el plazo de tiempo que permanecen activadas:</strong>
                  <ul>
                    <li><strong>Cookies de sesión:</strong> Son cookies temporales que permanecen en el archivo de cookies de tu navegador hasta que abandonas la página web.</li>
                    <li><strong>Cookies persistentes:</strong> Son almacenadas en el disco duro y nuestra web las lee cada vez que realizas una nueva visita.</li>
                  </ul>
                </li>
                <li><strong>Según su finalidad:</strong>
                  <ul>
                    <li>Cookies técnicas,</li>
                    <li>Cookies de personalización,</li>
                    <li>Cookies de análisis,</li>
                    <li>Cookies publicitarias,</li>
                    <li>Cookies de publicidad comportamental.</li>
                  </ul>
                </li>
              </ul>

              <p><strong>Finalidad de cada una de las cookies utilizadas:</strong></p>
              <p>A continuación, detallamos las cookies utilizadas en nuestra web y la finalidad de las mismas.</p>

              <p><strong>¿Cómo puedes configurar o deshabilitar tus cookies?</strong></p>
              <p>Puedes permitir, bloquear o eliminar las cookies instaladas en su equipo mediante la configuración de las opciones de tu navegador de Internet. En caso de que no permitas la instalación de cookies en tu navegador es posible que no puedas acceder a algunos de los servicios y que tu experiencia en nuestra web pueda resultar menos satisfactoria.</p>
              html
              Copy code
              <p>Relación de cookies utilizadas en el dominio WWW.TUTIENDADELMOVIL.COM:</p>
              <table>
                <tr>
                  <th>NOMBRE</th>
                  <th>FINALIDAD</th>
                  <th>PROPIA/TERCEROS</th>
                  <th>EXCLUÍDA/NO EXCLUIDA DEL DEBER DE LA INFORMACIÓN Y CONSENTIMIENTO</th>
                </tr>
                <tr>
                  <td>PHPSESSID</td>
                  <td>COOKIE DE SESIÓN</td>
                  <td>PROPIA</td>
                  <td>NO EXCLUÍDA</td>
                </tr>
              </table>

              <p>Relación de terceros prestadores de servicios de cookies en el dominio WWW.TUTIENDADELMOVIL.COM:</p>
              <table>
                <tr>
                  <th>NOMBRE PROVEEDOR</th>
                  <th>FINALIDAD</th>
                  <th>DESCRIPCIÓN DE LA FINALIDAD DE LA COOKIE</th>
                </tr>
                <tr>
                  <td>GOOGLE</td>
                  <td>ANÁLISIS Y TÉCNICAS</td>
                  <td>RECOPILAR INFORMACIÓN, INCLUIDA LA IP DEL USUARIO, QUE SERÁ TRANSMITIDA, TRATADA Y ALMACENADA POR GOOGLE EN LOS TÉRMINOS Y CONDICIONES FIJADOS EN LA WEB google.com</td>
                </tr>
              </table>

              <p>NOTA: Esta lista se actualizará con la mayor celeridad posible a medida que cambien los servicios del sitio web ofrecidos en el mismo. Sin embargo, ocasionalmente durante esta actualización puede ser que la lista no incluya ya una cookie, aunque siempre se referirá a cookies con propósitos idénticos a los registrados en esta lista.</p>

              <p>¿Cómo puedes configurar o deshabilitar tus cookies?</p>
              <p>Puedes permitir, bloquear o eliminar las cookies instaladas en su equipo mediante la configuración de las opciones de tu navegador de Internet. En caso de que no permitas la instalación de cookies en tu navegador es posible que no puedas acceder a algunos de los servicios y que tu experiencia en nuestra web pueda resultar menos satisfactoria.</p>

            </>} />
          }
        />

      </Routes>
      {location.pathname !== "/cart" && <Footer />}
    </CartContextProvider>
  );
}

export default App;
