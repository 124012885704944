import React, { useEffect, useState } from "react";
import "./product.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Navigation, Thumbs } from "swiper";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useCartContext } from "../../contexts/CartContext";

const Product = () => {
  const { cart, addProduct } = useCartContext();
  const [product, setProduct] = useState({});
  const navigate = useNavigate();
  const [checkOut, setCheckOut] = useState(false);
  const [loading, setLoading] = useState(true);
  const [quantity, setQuantity] = useState(1);
  const id = useParams();
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  useEffect(() => {
    const FetchProduct = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/products/getproduct/${id.id}`,
          {withCredentials: true}
        );
        setProduct(res.data[0]);
      } catch (err) {
        console.log(err);
      }
    };
    FetchProduct();
  }, []);


  const encodeUrl = (url) => {
    // Split the URL at the last '/' to get the file name separately
    const parts = url.split('/');
    const fileName = parts.pop();

    // Encode the file name
    const encodedFileName = encodeURIComponent(fileName);

    // Reconstruct the URL with the encoded file name
    return `${parts.join('/')}/${encodedFileName}`;
  };

  const HandleAddCart = () => {
    if (!cart?.filter((elem) => { return elem._id === product._id }).length > 0) {
      addProduct(product, quantity, product.price * quantity);
    }
    navigate("/cart");
  };

  useEffect(() => {
    setLoading(true);
    const products = cart?.products?.filter((elem) => {
      return elem.productId === product._id;
    });
    if (products?.length > 0) {
      setCheckOut(true);
    }
    setLoading(false);
  }, []);

  if (loading) return "LOADING!";

  return (
    <div className="Product mt-3">
      <div className="d-md-flex justify-content-between canvas">
        <div className="d-lg-flex flex-lg-row-reverse Slider w-50 me-lg-5 me-md-3">
          <Swiper
            style={{
              "--swiper-navigation-color": "#fff",
              "--swiper-pagination-color": "#fff",
            }}
            loop={true}
            spaceBetween={10}
            navigation={true}
            thumbs={{
              swiper:
                thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
            }}
            modules={[FreeMode, Navigation, Thumbs]}
            className="mySwiper2"
          >
            {product.images?.map((elem) => {
              return (
                <SwiperSlide key={elem}>
                  <div
                    style={{
                      background: `url(${process.env.REACT_APP_BASE_URL + "/" + elem.replaceAll(" ", "%20")})`,
                    }}
                    className="productImg"
                  ></div>
                </SwiperSlide>
              );
            })}
          </Swiper>
          <Swiper
            onSwiper={setThumbsSwiper}
            spaceBetween={10}
            slidesPerView={4}
            freeMode={true}
            direction="vertical"
            grabCursor={true}
            watchSlidesProgress={true}
            modules={[FreeMode, Navigation, Thumbs]}
            className="mySwiper flex-column me-3  d-none d-lg-flex"
          >
            {product.images?.map((elem) => {
              return (
                <SwiperSlide key={elem}>
                  <div
                    style={{
                      background: `url(${(process.env.REACT_APP_BASE_URL + "/" + elem.replaceAll(" ", "%20"))})`}}
                    className="SproductImg"
                  ></div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>

        <div className="details w-100">
          <p
            className="text-muted d-md-block d-none"
            style={{ wordSpacing: "5px" }}
          >
            Home › {product.category} ›{" "}
            <span className="fw-bold text-dark">{product.subcategory}</span>{" "}
          </p>
          <h1 className="fw-bold fs-2 mt-3">{product.title}</h1>
          <h1 className="fw-bold fs-2 mt-3 price">$ {product.price * quantity}</h1>

          <div className="d-flex align-items-center mt-3">
            <button className="text-white btn px-3  py-1  fs-5 bg_prim" onClick={() => setQuantity(quantity > 1 ? quantity - 1 : 1)}>-</button>
            <p className="px-5 fw-bold fs-5">{quantity}</p>
            <button className="text-white btn px-3  py-1  fs-5 bg_prim" onClick={() => setQuantity(quantity + 1)}>+</button>
          </div>
          {!checkOut ? (
            <div className="d-flex mt-3">
            <button className="cartBtn" onClick={HandleAddCart}>
              ADD TO CART
            </button>
            

            <button className="cartBtn ms-3" onClick={()=> navigate("/")}>
            VOLVER A TIENDA
            </button>
            </div>
          ) : (
            <button className="cartBtn mt-3">Continue to checkout</button>
          )}
          <div className="description mt-3">
            <p className="fw-bold">Product Description:</p>
            <p>{product.description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;
