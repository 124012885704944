import React from "react";
import Hero from "../../components/Hero";
import Collection from "../../components/Collection";
import "./home.css";
import NewArrival from "../../components/NewArrival";
import HeroRow from "../../components/HeroRow";
import BrandSlider from "../../components/BrandSlider";

const Home = () => {
  return (
    <section className="Home">
      <Hero />
      <HeroRow />
      <Collection />
      <BrandSlider/>
      <NewArrival />
    </section>
  );
};

export default Home;
