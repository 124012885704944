import React, { useState } from "react";
import "./auth.css";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import loginImg from "../../images/register2.svg";
import { useAuthContext } from "../../contexts/AuthContext";

const Login = () => {
  const [credentials, setCredentials] = useState({});
  const { setUser, user } = useAuthContext();
  const navigate = useNavigate();

  const handleChange = (e) => {
    setCredentials((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const handleLogin = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/auth/login`,
        credentials,
        {
          withCredentials: true
        }
      );
      const json = await res.data;
      setCredentials({ email: "", password: "" });

      if (json.success) {
        const { data: userData } = await axios.get(process.env.REACT_APP_BASE_URL + "/api/auth/userinfo", { withCredentials: true });
        setUser(userData);
        navigate("/");
      } else {
        alert(json.message);
      }


    } catch (err) {
      console.log(err.response.data);
    }
  };

  if (user) return <Navigate to={"/"} />;

  return (
    <div className="canvas mt-3">
      <div className="d-flex  mt-5 align-items-center">
        {/* <div style={{ fontSize: "1.1rem", padding: window.matchMedia("(max-width: 550px)").matches ? "7px 17.5px" : "10px 20px", borderRadius: "50%", border: "1.99px solid black" }}>i</div> */}
        <h1 className="fw-bold fs-1 ms-3">¡Entrar!</h1>
      </div>
      <div className="col-12 d-flex flex-wrap align-items-center mt-4 gap-y-2">
        <div className="col-lg-6 col-12 mt-2">
          <img src={loginImg} className="col-lg-8 col-6 mx-auto d-block" />
        </div>
        <div className="col-lg-6 col-12 mt-2">
          <div className="mt-2">
            <label>Email</label>
            <input
              type={"email"}
              className="form-control"
              value={credentials.email}
              onChange={(e) => handleChange(e)}
              id="email"
            />
          </div>
          <div className="mt-2">
            <label>Contraseña</label>
            <input
              type={"password"}
              className="form-control"
              value={credentials.password}
              id="password"
              onChange={(e) => handleChange(e)}
            />
          </div>
          <button className="auth-btn mt-3" onClick={handleLogin}>
            Acceder
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
