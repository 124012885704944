import React, { useEffect } from "react";
import { BiSearch } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import { BsFillCartFill } from "react-icons/bs";
import { useCartContext } from "../contexts/CartContext";
import logo from "../images/logo.png";
import { useAuthContext } from "../contexts/AuthContext";
import axios from "axios";

const Navbar = () => {
  const navigate = useNavigate();
  const { cart, orders } = useCartContext();
  const { user, setUser } = useAuthContext()



  const logout = async () => {
    try {
      await axios.post(process.env.REACT_APP_BASE_URL + "/api/auth/logout", {}, { withCredentials: true });
      setUser(false)
      navigate("/login");
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <nav className="navbar navbar-expand-lg Navbar">
      <div className="canvas">
        <div className="d-flex flex-wrap container-fluid">
          <Link to="/" className="navbar-brand fw-bold fs-4">
            {/* Mycellphone<span className="highlightP" style={{fontSize: "12px"}}>.shop</span> */}
            <img src={logo} alt="mycellphone logo" height={50} />
          </Link>
          <button
            className="navbar-toggler d-block d-xl-none ms-auto"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse ms-xl-4 justify-content-end align-items-center"
            id="navbarSupportedContent"
          >
            <div className="d-flex align-items-center mb-3 mb-md-0">
              <form className="d-flex w-100" role="search">
                <div className="d-flex searchBar mx-auto mt-1">
                  <input type={"text"} placeholder="Buscar Productos" />
                  <button
                    className="d-flex justify-content-center align-items-center"
                    type="submit"
                  >
                    <BiSearch color="white" size={20} />
                  </button>
                </div>
              </form>
            </div>
            {!user ? (
              <>
                <Link to="/login" className="button-outline ms-4">
                  Mi Cuenta
                </Link>
                <Link to={"/register"} className="button ms-2">
                  Registro
                </Link>
              </>
            ) : (
              ""
            )}
            <Link to="/cart" className="text-dark cart-icon">
              <div className="items">
                {!cart?.length > 0 ? 0 : cart?.length}
              </div>
              <BsFillCartFill size={25} className="ms-3" />
            </Link>
            {user && (
              <Link to="/orders" className="nav-link ms-3 highlight ms-3">
                Orders
              </Link>
            )}
            {user && (
              <button className="button bg-danger ms-3"
                onClick={() => {
                  logout()
                }}>
                Logout
              </button>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
