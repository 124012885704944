import React from "react";
import { Link } from "react-router-dom";
import MailImg from "../images/mail.png";
import { BiSearch } from "react-icons/bi";
import geslopd from "../images/geslopd.png"

const Footer = () => {
  return (
    <footer>
      <div className="d-flex canvas justify-content-between flex-wrap footer-rows py-2">
        <div className="footer-col">
          <h6 className="fw-bold">Nosotros</h6>
          <Link to={"/"} className="d-block mt-3 nav-link">
            About
          </Link>
          <Link to={"/"} className="d-block mt-1 nav-link">
            Contact us
          </Link>
          <Link to={"/"} className="d-block mt-1 nav-link">
            Shop
          </Link>
        </div>
        <div className="footer-col">
          <h6 className="fw-bold">Ayuda</h6>
          <Link to={"/"} className="d-block mt-3 nav-link">
            Shipping Info
          </Link>
          <Link to={"/"} className="d-block mt-1 nav-link">
            Refund Policy
          </Link>
          <Link to={"/"} className="d-block mt-1 nav-link">
            FAQ
          </Link>
          <Link to={"/terms"} className="d-block mt-1 nav-link">
            Terms and condition
          </Link>
        </div>
        <div className="footer-col">
          <h6 className="fw-bold">Información</h6>
          <Link to={"/privacy"} className="d-block mt-3 nav-link">
            Privacy Policy
          </Link>
          <Link to={"/aviso"} className="d-block mt-1 nav-link">
            Aviso Legal
          </Link>
          <Link to={"/cookies"} className="d-block mt-1 nav-link">
            Política de Cookies
          </Link>
          <Link to={"/protection"} className="d-block mt-1 nav-link">
            Protección de Datos
          </Link>
        </div>
        <div className="footer-col">
          <img src={geslopd} height={125}/>
        </div>
      </div>
      <p className="text-center mt-3">
        Copyright ©️ Tutiendadelmovil.com • Todos los Derechos Reservados
      </p>
    </footer>
  );
};

export default Footer;
