import React, { useState } from "react";
import "./auth.css";
import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector";
import axios from "axios";
import { Navigate } from "react-router-dom";
import registerImg from "../../images/register1.svg";
import { useAuthContext } from "../../contexts/AuthContext";

const Register = () => {
  const [credentials, setCredentials] = useState({});
  const {user} = useAuthContext()

  const handleChange = (e) => {
    setCredentials((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const handleRegister = async () => {
    if (credentials.password === credentials.cpassword) {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/auth/register`,
          credentials,
          {
           withCredentials: true
          }
        );
        const json = await res.data;
        setCredentials({ name: "", email: "", password: "", surname: "", cpassword: "" });
        if (json.success) {
          alert("¡Tu Cuenta ha sido creada!", "primary");
        } else {
          alert("Something went wrong!", "danger");
        }
      } catch (err) {
        console.log(err.response.data);
      }
    } else {
      alert("CONTRASEÑAS NO COINCIDEN!");
    }
  };

  if (user) return <Navigate to={"/"} />;

  return (
    <div className="canvas mt-3">
      <div className="d-flex  mt-5 align-items-center">
        {/* <div style={{fontWeight: "bold",  fontSize: "1.1rem", padding: window.matchMedia("(max-width: 550px)").matches ? "0px 11px":  "10px 20px", borderRadius: "50%", border: "1.99px solid black" }}>i</div> */}
        <h1 className="fw-bold ms-3 fs-1">¡Abre tu Cuenta!</h1>
      </div>
      <div className="col-12 d-flex flex-wrap align-items-center mt-4">
        <div className="col-lg-6 col-12 mt-3">
          <img src={registerImg} className="col-lg-8 col-10 mx-auto d-block" />
        </div>
        <div className="col-lg-6 col-12 mt-3">
          <div>
            <label>Nombres</label>
            <input
              type={"text"}
              className="form-control"
              onChange={(e) => handleChange(e)}
              value={credentials.name}
              id="name"
            />
          </div>
          <div>
            <label>Apellidos</label>
            <input
              type={"text"}
              className="form-control"
              onChange={(e) => handleChange(e)}
              value={credentials.surname}
              id="surname"
            />
          </div>
          <div className="mt-2">
            <label>Email</label>
            <input
              type={"email"}
              className="form-control"
              value={credentials.email}
              onChange={(e) => handleChange(e)}
              id="email"
            />
          </div>
          <div className="mt-2">
            <label>Contraseña</label>
            <input
              type={"password"}
              className="form-control"
              placeholder="Crea tu contraseña"
              id="password"
              value={credentials.password}
              onChange={(e) => handleChange(e)}
            />
            <input
              type={"password"}
              className="form-control mt-2"
              placeholder="Confirmar Contraseña"
              id="cpassword"
              value={credentials.cpassword}
              onChange={(e) => handleChange(e)}
            />
          </div>
          <button className="auth-btn mt-3" onClick={handleRegister}>
            REGISTRARME
          </button>
        </div>
      </div>
    </div>
  );
};

export default Register;
