import React from "react";
import Collection1 from "../images/c1.png";
import Collection2 from "../images/c2.png";

const Collection = () => {
  return (
    <div>
      <h1 className="text-center fw-bold mt-5">Ofertas y Promociones</h1>
      <div
        className="canvas d-flex flex-wrap justify-content-between 
    flex-lg-row flex-direction-column collections mt-1"
      >
        <img
          src={Collection1}
          alt="img"
          className="w-40 w-sm-100 mt-4 rounded-5"
        />
        <img src={Collection2} alt="img" className="w-40 w-sm-100 mt-4 rounded-5" />
      </div>
    </div>
  );
};

export default Collection;
