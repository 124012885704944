import React from 'react'
import { FaShippingFast } from "react-icons/fa";
// import { MdDiscount } from "react-icons/md";
import { TbDiscount2 } from "react-icons/tb";

import { BiSupport } from "react-icons/bi";

const HeroRow = () => {
    return (
        <div className="bg-light border-bottom py-4">
            <div className="canvas">
                <div className='col-lg-10 col-xl-8 col-12 mx-auto d-flex justify-content-between flex-wrap'>
                    <div className="col-lg-4 col-xl-4 col-sm-4 col-12 hero_card d-flex align-items-center justify-content-center">
                        <FaShippingFast size={30} color='black' />
                        <div className="ms-3">
                            <p className='fw-bold'>ENVÍO GRATIS</p>
                            <p className='text-muted'>¡En todos tus pedidos!</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-xl-4 col-sm-4 col-12 hero_card d-flex align-items-center justify-content-center">
                        <TbDiscount2 size={30} color='black' />
                        <div className="ms-3">
                            <p className='fw-bold'>MEJORES PRECIOS</p>
                            <p className='text-muted'>¡Ahorra mucho más!</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-xl-4 col-sm-4 col-12  hero_card d-flex align-items-center justify-content-center">
                        <BiSupport size={30} color='black' />
                        <div className="ms-3">
                            <p className='fw-bold'>SOPORTE 24/7</p>
                            <p className='text-muted'>Atención Personalizada</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeroRow