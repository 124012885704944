import React, { useEffect } from "react";
import { useCartContext } from "../../contexts/CartContext";

const Order = () => {
  const { orders, Fetchorders } = useCartContext();

  useEffect(() => {
    Fetchorders()
  }, [])
  

  return (
    <div className="products w-50 p-3">
      {orders?.map((product) => {
        return (
          <div
            key={product.productId}
            className="d-flex align-items-center cartCard mt-4"
          >
            {product.products?.map((elem) => {
              return <>
                <div className="me-3">
                  <img src={`${process.env.REACT_APP_BASE_URL}/` + elem.img} alt="img" />
                </div>
                <div>
                  <p className="fs-5 mt-2">{elem.name}</p>
                  <p className="mt-2">Qty: {elem.quantity}</p>
                  <p className="mt-2 fw-bold">{elem.price}</p>
                </div>
              </>
            })}
          </div>
        );
      })}
      <div
        className="d-flex  align-items-center 
  fw-bold fs-5 mt-3 total justify-content-end"
      >
        <p>Total:</p>
        <p className="ms-3">$ {orders.reduce((acc, curr)=> Number(acc) + Number(curr.bill), 0)}</p>
      </div>
    </div>
  );
};

export default Order;
