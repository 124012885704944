import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useCartContext } from "../../contexts/CartContext";
import "./cart.css";
import axios from "axios";

const Cart = () => {
  const navigate = useNavigate();
  const { cart, removeProduct, total, Fetchcart } = useCartContext();
  const [loading, setLoading] = useState(false)

  const editCart = (q, id, price) => {
    const json = JSON.parse(localStorage.getItem("cart"));

    let index = json.findIndex((obj) => obj._id === id);

    if (index !== -1) {
      json[index].quantity = q;
      json[index].total = price * q;
    } else {
      console.log("Object with id 2 not found.");
    }

    localStorage.setItem("cart", JSON.stringify(json));

    Fetchcart();
  };

  const makeRequest = async () => {
    if(cart.length > 0){
    try {
      setLoading(true)
      const order = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/orders/create`,
        { cart, total  },
        { withCredentials: true }
      );
      // console.log(order);
      alert("Pedido Recibido")
      localStorage.setItem("cart", JSON.stringify([]));
      Fetchcart();
      setLoading(false)
    } catch (err) {
      console.log(err);
      setLoading(false)
    }
  }
  };

  const deleteProduct = async (id) => {
    await removeProduct(id);
  };

  return (
    <div className="d-flex CartSection">
      <div className="canvas mt-3">
        <div className="d-flex align-items-start justify-content-between">
          <div className="products w-50 p-3">
            {!cart?.length === 0 && (
              <p className="text-center">CART IS EMPTY</p>
            )}
            {cart?.map((product) => {
              return (
                <div
                  key={product._id}
                  className="d-flex align-items-start cartCard mt-4"
                >
                  <div className="me-3">
                    <img
                      src={
                        `${process.env.REACT_APP_BASE_URL}/` + product?.images[0]
                      }
                      alt="img"
                    />
                  </div>
                  <div>
                    <p className="fw-bold fs-5">{product.title}</p>
                    <div className="d-flex align-items-center my-1">
                      <button
                        className="text-white btn px-2  py-0  fs-6 bg_prim"
                        onClick={() => {
                          if (product.quantity > 1) {
                            editCart(
                              product.quantity - 1,
                              product._id,
                              product.price
                            );
                          }
                        }}
                      >
                        -
                      </button>
                      <p className="px-3 fw-bold fs-5">{product.quantity}</p>
                      <button
                        className="text-white btn px-2  py-0  fs-6 bg_prim"
                        onClick={() =>
                          editCart(
                            product.quantity + 1,
                            product._id,
                            product.price
                          )
                        }
                      >
                        +
                      </button>
                    </div>
                    <p className="fs-5 fw-bold">
                      $ {Number(product.total).toLocaleString()}
                    </p>

                    <button
                      className="btn btn-danger px-2 py-1 mt-2"
                      onClick={() => deleteProduct(product._id)}
                    >
                      Remove
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="bg-white rounded w-25 p-3">
            <h1 className="fw-bold fs-5 mb-4">Summary</h1>
            <p>
              Total amount: <b>$ {Number(total).toLocaleString()}</b>
            </p>
            <p className="fw-bold">Items:</p>
            <small className="mb-0 text-muted">Iphone 14 pro max</small>
            <br />
            <small className="mb-0 text-muted">Redmi 12 pro max</small>
            <button
              className="button mt-3 w-100 mx-auto d-block"
              onClick={makeRequest}
            >
             {loading ? "Loading..." :  "Check Out"}
            </button>

            <button className="button bg_prim mt-3 w-100 mx-auto d-block" onClick={()=> navigate("/")}>
            VOLVER A TIENDA
            </button>
          </div>
          {/* } */}
        </div>
.
        <div className="Payment">
          {/* <Checkout
            user={cart.userId}
            amount={cart.bill}
            checkout={checkout}
          /> */}
          {/* <StripeCheckout
            name="BeSassy"
            billingAddress
            shippingAddress
            description={`Your total is $${cart.bill}`}
            amount={cart.bill * 100}
            token={onToken}
            stripeKey={
              "pk_test_51LzzN5SJ1EQBxXRrXBEoUXGdfHFdDB5WksWaUgBuN58UIiAfzuNMMZlnEGFHQ8KVnYup1wPstEumPBNQYh56eqcr00LEC3SUyz"
            }
          >
            <button>CHECKOUT NOW</button>
          </StripeCheckout> */}
        </div>
      </div>
    </div>
  );
};

export default Cart;
